import React, { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './LeaderboardPage.scss';
import { useSocket, useSocketListener } from '../hooks/useSocket';
import { withSocket } from '../hoc/withSocket';
import { BeerData } from '../types/beer.model';
import { Button } from 'primereact/button';
import { ToastContext } from '../App';
import { roundNumber } from '../api/common';

interface RankingItem {
    totalScore: number;
    beer: BeerData;
}

function LeaderboardPage() {
    const [ranking, setRanking] = useState<RankingItem[]>([]);
    const toast = useContext(ToastContext);
    const [isLoading, setIsLoading] = useState(false);

    const socket = useSocket();
    useSocketListener<{ totalScore: number; beer: BeerData }[]>(
        'listLeaderboardResult',
        (ranking) => {
            console.log('');
            setRanking(ranking);
        },
        [setRanking]
    );

    useSocketListener<BeerData>(
        'beerPublished',
        (beer) => {
            setIsLoading(false);
            if (!beer) {
                toast?.show({ severity: 'error', summary: 'Fejl', detail: 'Alle øl er allerede udtrukket' });
                return;
            }

            toast?.show({ severity: 'success', summary: 'Ny øl udtrukket!', detail: beer.name });
        },
        [toast, setIsLoading]
    );

    useEffect(() => {
        // Emit beerlist call
        socket.emit('listLeaderboard');
    }, [socket]);

    const publishBeer = () => {
        setIsLoading(true);
        socket.emit('publishBeer');
    };

    return (
        <div className='leaderboard-page' style={{ background: "url('/assets/img/christmas-bg.jpg')" }}>
            <h1 className='leaderboard-page__title'>
                <span>Scoreboard</span>
                <Button onClick={publishBeer} className='p-button-success p-button-rounded'>
                    Udtræk ny øl
                </Button>
                {isLoading ? <i className='fas fa-spinner fa-spin'></i> : ''}
            </h1>
            {ranking.length === 0 ? <i>Ingen ranking endnu</i> : ''}
            <div className='leaderboard-page__list'>
                {ranking.map(({ beer, totalScore }, i) => (
                    <div key={beer.id} className='leaderboard-page__item' style={{ border: beer.current ? '5px solid #279c3a' : undefined }}>
                        <div className='leaderboard-page__item__rank'>
                            <div className='leaderboard-page__item__rank__placement'>#{i + 1}</div>
                            <div className='leaderboard-page__item__rank__score'>{totalScore.toFixed(0)}p</div>
                        </div>
                        <img src={beer.image} alt='Billede' className='leaderboard-page__item__photo'></img>
                        <div className='leaderboard-page__item__details'>
                            <div className='leaderboard-page__item__name'>{beer.name}</div>
                            <div className='leaderboard-page__item__carrier'>{beer.owner}</div>
                        </div>
                        {beer.current ? <i className='fas fa-star'></i> : ''}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default withSocket(LeaderboardPage);
