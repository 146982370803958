import { ReactFacebookLoginInfo } from 'react-facebook-login';
import { decodeParams, encodeParams, makeRandomString } from './common';

type WindowWithFB = Window & typeof globalThis & { FB: FB | undefined; fbAsyncInit: Function | undefined };

interface FB {
    login: (callback: (response: { status: string; authResponse?: UserInfo }) => any, options: { scope: string }) => void;
    logout: (callback: () => any) => void;
    getLoginStatus: (callback: (response: { status: string; authResponse?: UserInfo }) => void) => void;
    init: (options: { version: string; appId: string; xfbml: boolean; cookie: boolean }) => void;
}

interface OnReadyState {
    state: 'no_login' | 'authorized' | 'error';
    userInfo?: UserInfo;
    errorInfo?: {
        error: string;
        error_reason: string;
        error_description: string;
    };
}

interface AuthorizationData {
    access_token: string;
    data_access_expiration_time: number;
    expires_in: number;
}

export interface UserInfo extends ReactFacebookLoginInfo {
    first_name?: string;
}

type OnReadyHandler = (state: OnReadyState) => void;

export class Facebook {
    private readonly appId = '586426192172498';
    private onReadyHandlers: OnReadyHandler[] = [];

    public get window() {
        return window as WindowWithFB;
    }

    public get redirectUri() {
        return this.window.location.origin;
    }

    private get searchUrl() {
        const parts = this.window.location.href.split('?');

        if (parts.length === 2) {
            return `?${parts[1].replace('#', '')}`;
        }

        return '';
    }

    private get isValidFacebookCallback() {
        const params = this.searchUrl;
        const stateParam = decodeParams(params, 'state');

        // If state matches, then check if we should login
        if (this.getStateParam() === stateParam) {
            return Boolean(decodeParams(params, 'access_token') || decodeParams(params, 'granted_scopes'));
        }

        return false;
    }

    private get isErrorFacebookCallback() {
        const params = this.searchUrl;
        const errorParam = decodeParams(params, 'error');

        return Boolean(errorParam);
    }

    constructor(public userInfo?: UserInfo, onReady?: OnReadyHandler) {
        if (onReady) {
            this.onReadyHandlers.push(onReady);
        }

        if (this.isValidFacebookCallback) {
            // Successful response received in query parameters from Facebook
            const params = this.searchUrl;
            const accessTokenParam = decodeParams(params, 'access_token');
            const dataAccessExpirationTimeParam = decodeParams(params, 'data_access_expiration_time');
            const expiresInParam = decodeParams(params, 'expires_in');
            const authorizationData: AuthorizationData = {
                access_token: accessTokenParam,
                data_access_expiration_time: Number(dataAccessExpirationTimeParam),
                expires_in: Number(expiresInParam),
            };

            this.getUserInfo(authorizationData).then((userInfo: UserInfo) => {
                this.userInfo = userInfo;
                this.dispatchReadyCallbacks({
                    state: 'authorized',
                    userInfo,
                });
            });
        } else if (this.isErrorFacebookCallback) {
            // Error response received in query parameters from Facebook
            const params = window.location.search;
            const errorParam = decodeParams(params, 'error');
            const errorReasonParam = decodeParams(params, 'error_reason');
            const errorDescriptionParam = decodeParams(params, 'error_description');

            this.dispatchReadyCallbacks({
                state: 'error',
                errorInfo: {
                    error: errorParam,
                    error_reason: errorReasonParam,
                    error_description: errorDescriptionParam,
                },
            });
        } else {
            // No login response received
            this.dispatchReadyCallbacks({
                state: 'no_login',
            });
        }
    }

    private async getUserInfo({ access_token, ...authorizationData }: AuthorizationData) {
        const fields = ['name', 'picture', 'first_name'];
        return await fetch(`https://graph.facebook.com/me?access_token=${encodeURIComponent(access_token)}&fields=${encodeURIComponent(fields.join())}`)
            .then((res) => res.json())
            .then((data) => ({ accessToken: access_token, ...authorizationData, ...data } as UserInfo));
    }

    private getStateParam(overwrite = false) {
        let currentState = localStorage.getItem('oauthState');
        if (!currentState || overwrite) {
            currentState = makeRandomString(10);
            localStorage.setItem('oauthState', currentState);
        }

        return currentState;
    }

    private dispatchReadyCallbacks(state: OnReadyState) {
        for (const handler of this.onReadyHandlers) {
            handler(state);
        }
    }

    public onReady(handler: OnReadyHandler) {
        this.onReadyHandlers.push(handler);
    }

    public setUserInfo(userInfo: UserInfo) {
        this.userInfo = userInfo;
    }

    public redirectToLogin() {
        const params = {
            client_id: this.appId,
            redirect_uri: this.redirectUri,
            state: this.getStateParam(true),
            scope: 'public_profile,openid',
            response_type: 'token',
        };

        window.location.href = `https://www.facebook.com/dialog/oauth${encodeParams(params)}`;
    }

    public async logout() {
        if (this.userInfo) {
            const { accessToken, id } = this.userInfo;

            await fetch(`https://www.facebook.com/x/oauth/logout?access_token=${accessToken}`);
            await fetch(`https://graph.facebook.com/${id}/permissions?access_token=${accessToken}`, {
                method: 'DELETE',
            });
        }

        window.location.reload();
    }
}
