import React, { useContext, useMemo, useState } from 'react';
import './Nav.scss';

import { UserContext } from '../App';
import { Button } from 'primereact/button';
import { Facebook } from '../api/facebook';

export default function Nav() {
    const userInfo = useContext(UserContext);
    const fb = useMemo(() => new Facebook(userInfo!), [userInfo]);
    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const logout = async () => {
        setIsLoggingOut(true);
        console.log('logout', userInfo);
        if (userInfo) {
            localStorage.removeItem('userInfo');
            try {
                await fb.logout();
            } catch (err) {}

            window.location.href = '/login';
        }
    };

    return (
        <>
            <nav className='navigation'>
                <Button label='Log ud' icon='pi pi-arrow-left' iconPos='left' className='navigation__logout p-button-danger p-button-sm p-button-text' onClick={logout} />
                <div className='navigation__user'>
                    <span className='navigation__user__name'>{userInfo?.first_name}</span>
                    <img src={userInfo?.picture?.data.url} alt='profilbillede' className='navigation__user__avatar' />
                </div>
            </nav>
            {isLoggingOut && (
                <div className='navigation__loader'>
                    <i className='fas fa-spinner fa-spin'></i>
                    <p>Logger ud...</p>
                </div>
            )}
        </>
    );
}
