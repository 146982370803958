import React, { CSSProperties, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UserContext } from '../App';
import Nav from './Nav';
import './RankingPage.scss';
import furBock from '../assets/fur-bock.jpg';
import { BeerItem } from './BeerList';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableLocation } from 'react-beautiful-dnd';
import createIoSocket from 'socket.io-client';
import { useSocket, useSocketListener } from '../hooks/useSocket';
import { Button } from 'primereact/button';
import { SocketContext, withSocket } from '../hoc/withSocket';
import { BeerData } from '../types/beer.model';

// a little function to help us with reordering the result
const reorder = (list: BeerItem[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source: BeerItem[], destination: BeerItem[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {
        [droppableSource.droppableId]: sourceClone,
        [droppableDestination.droppableId]: destClone,
    };

    return result;
};

function RankingPage() {
    const [beerIsLoaded, setBeerIsLoaded] = useState(false);
    const userInfo = useContext(UserContext);

    const [unrankedBeers, setUnrankedBeers] = useState<BeerItem[]>([]);
    const [rankedBeers, setRankedBeers] = useState<BeerItem[]>([]);

    const listMap = useMemo<Record<string, BeerItem[]>>(
        () => ({
            rankedBeers,
            unrankedBeers,
        }),
        [rankedBeers, unrankedBeers]
    );

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(listMap[source.droppableId], source.index, destination.index);

            if (source.droppableId === 'rankedBeers') {
                setRankedBeers(items);
            } else if (source.droppableId === 'unrankedBeers') {
                setUnrankedBeers(items);
            }
        } else if (destination.droppableId === 'rankedBeers') {
            const result = move(listMap[source.droppableId], listMap[destination.droppableId], source, destination);

            setRankedBeers(result.rankedBeers);
            setUnrankedBeers(result.unrankedBeers);
        }
    };

    const socket = useSocket();

    useSocketListener<{ rankedBeers: BeerData[]; unrankedBeers: BeerData[] }>(
        'listBeersResult',
        ({ unrankedBeers, rankedBeers }) => {
            const mapper: (data: BeerData) => BeerItem = ({ id, owner, name, image }: BeerData) => ({
                id,
                carrier: owner,
                name,
                image,
            });

            // Set result
            setRankedBeers(rankedBeers.map(mapper));
            setUnrankedBeers(unrankedBeers.map(mapper));
        },
        [setRankedBeers, setUnrankedBeers]
    );

    useSocketListener(
        'beerPublished',
        () => {
            socket.emit('listBeers');
        },
        [socket]
    );

    useEffect(() => {
        // Emit beerlist call
        socket.emit('listBeers');
        socket.on('connect', () => {
            socket.emit('listBeers');
        });
    }, [socket]);

    useEffect(() => {
        socket.emit('updateBeerRanks', rankedBeers);
        if (rankedBeers) {
        }
    }, [rankedBeers, socket]);

    return (
        <div className='ranking-page'>
            <Nav />

            <DragDropContext onDragEnd={onDragEnd}>
                <div className='ranking-page__header'>
                    <label className='ranking-page__header__title'>Ikke rangede øl</label>
                </div>
                <Droppable droppableId='unrankedBeers'>
                    {(provided) => (
                        <>
                            {unrankedBeers.length === 0 ? <i>Ingen øl at rangere</i> : ''}
                            <div ref={provided.innerRef} className='ranking-page__list'>
                                {unrankedBeers.map((beer, index) => (
                                    <Draggable key={beer.id} draggableId={beer.id} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} className='ranking-page__item'>
                                                <span className='ranking-page__item__rank'></span>
                                                <img src={beer.image} className='ranking-page__item__photo' alt='bajer' />
                                                <div className='ranking-page__item__details'>
                                                    <span className='ranking-page__item__name'>{beer.name}</span>
                                                    <span className='ranking-page__item__carrier'>{beer.carrier}</span>
                                                </div>
                                                <div className='ranking-page__item__drag' {...provided.dragHandleProps}>
                                                    <i className='fas fa-bars'></i>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </div>
                        </>
                    )}
                </Droppable>
                <div className='ranking-page__header'>
                    <label className='ranking-page__header__title'>Rangede øl</label>
                </div>
                <Droppable droppableId='rankedBeers'>
                    {(provided, snapshot) => (
                        <div ref={provided.innerRef} className='ranking-page__list'>
                            {rankedBeers.map((beer, index) => (
                                <Draggable key={beer.id} draggableId={beer.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} className='ranking-page__item'>
                                            <span className='ranking-page__item__rank'>#{index + 1}</span>
                                            <img src={beer.image} className='ranking-page__item__photo' alt='bajer' />
                                            <div className='ranking-page__item__details'>
                                                <span className='ranking-page__item__name'>{beer.name}</span>
                                                <span className='ranking-page__item__carrier'>{beer.carrier}</span>
                                            </div>
                                            <div className='ranking-page__item__drag' {...provided.dragHandleProps}>
                                                <i className='fas fa-bars'></i>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export default withSocket(RankingPage);
