import { useContext, useEffect, useMemo, useRef } from 'react';
import createIoSocket from 'socket.io-client';
import { UserContext } from '../App';
import { SocketContext } from '../hoc/withSocket';

export const useSocket = (namespace?: string | null) => {
    const socketRef = useRef<SocketIOClient.Socket>();
    const contextualSocket = useContext(SocketContext);
    socketRef.current = contextualSocket ?? undefined;

    const userInfo = useContext(UserContext);

    if (!socketRef.current) {
        let endpoint = process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT;
        if (namespace) {
            endpoint += `/${namespace}`;
        }

        // Set socket
        socketRef.current = createIoSocket(endpoint!, {
            transports: ['polling', 'websocket'],
            transportOptions: {
                polling: {
                    extraHeaders: {
                        Authorization: `Bearer ${userInfo?.accessToken}`,
                        'X-User-Id': userInfo?.id ?? '',
                        'X-User-Name': userInfo?.name ?? '',
                    },
                },
            },
        });
    }

    return socketRef.current!;
};

export const useSocketListener = <T = string>(listenToEvent: string, callback: (data: T) => any, deps: any[] = []) => {
    const socket = useSocket(null);

    useEffect(() => {
        const handler = async (data: T) => {
            console.log('Incomming data...');
            const result = await Promise.resolve(callback(data));

            if (result) {
                socket.emit(result);
            }
        };

        socket.on(listenToEvent, handler);

        // Clean up
        return () => {
            socket.off(listenToEvent, handler);
        };
    }, [callback, socket, listenToEvent, ...deps]);
};
