import React, { ComponentType } from 'react';
import { Component, FunctionComponent } from 'react';
import { useSocket } from '../hooks/useSocket';

interface WithSocketOptions {
    endpoint?: string;
}

export const SocketContext = React.createContext<SocketIOClient.Socket | null>(null);

export const withSocket = <P extends {}>(Component: ComponentType<P>, options: WithSocketOptions = {}) => {
    const { endpoint }: WithSocketOptions = {
        endpoint: process.env.REACT_APP_BACKEND_SOCKET_ENDPOINT,
        ...options,
    };

    return (props: P) => {
        const socket = useSocket(null);

        return (
            <SocketContext.Provider value={socket ?? null}>
                <Component {...props} />
            </SocketContext.Provider>
        );
    };
};
