import React, { useEffect, useMemo, useRef, useState } from 'react';
import './App.scss';
import FacebookLoginButton, { ReactFacebookFailureResponse } from 'react-facebook-login';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Facebook, UserInfo } from './api/facebook';
import RankingPage from './components/RankingPage';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useParams } from 'react-router-dom';
import { JsxAttribute } from 'typescript';
import LeaderboardPage from './components/LeaderboardPage';
import { Toast } from 'primereact/toast';

export const UserContext = React.createContext<UserInfo | null>(null);
export const ToastContext = React.createContext<Toast | null>(null);

function isSuccessfulFacebookResponse(response: ReactFacebookFailureResponse | UserInfo): response is UserInfo {
    if ('accessToken' in response) {
        return true;
    }

    return false;
}

function App() {
    const [isSavedUserInfoInvalid, setIsSavedUserInfoInvalid] = useState(false);
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isFacebookLoaded, setIsFacebookLoaded] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<JSX.Element | undefined>();

    const toastRef = useRef<Toast>(null);

    useEffect(() => {
        const encodedUserInfo = localStorage.getItem('userInfo');
        if (encodedUserInfo) {
            const userInfo = JSON.parse(encodedUserInfo) as UserInfo;
            setUserInfo(userInfo);
            return;
        }

        setIsSavedUserInfoInvalid(true);
    }, [isSavedUserInfoInvalid, setIsSavedUserInfoInvalid, setUserInfo]);

    const fb = useMemo(
        () =>
            new Facebook(undefined, ({ state, userInfo, errorInfo }) => {
                switch (state) {
                    case 'authorized':
                        localStorage.setItem('userInfo', JSON.stringify(userInfo!));
                        setIsSavedUserInfoInvalid(false);
                        setUserInfo(userInfo!);
                        window.location.href = window.location.origin;

                        break;

                    case 'error':
                        setError(`Login fejl = ${errorInfo!.error}: ${errorInfo!.error_reason} (${errorInfo!.error_description})`);
                        break;

                    case 'no_login':
                        break;
                }

                setIsFacebookLoaded(true);
            }),
        [setUserInfo, setError, setIsFacebookLoaded, setIsSavedUserInfoInvalid]
    );

    // Redirecter
    useEffect(() => {
        if (userInfo) {
            setRedirect(<Redirect to='/ranking' />);
        } else if (isSavedUserInfoInvalid && isFacebookLoaded) {
            setRedirect(<Redirect to='/login' />);
        }
    }, [userInfo, isSavedUserInfoInvalid, isFacebookLoaded]);

    return (
        <div className='app'>
            <UserContext.Provider value={userInfo}>
                <ToastContext.Provider value={toastRef.current}>
                    <Router>
                        <div>
                            {error && <Message severity='error' text={error} style={{ display: 'block', margin: '0 -15px' }} />}
                            <Switch>
                                <Route path='/ranking'>
                                    {userInfo ? <RankingPage /> : ''}
                                    {isSavedUserInfoInvalid && isFacebookLoaded ? <Redirect to='/login' /> : ''}
                                </Route>
                                <Route path='/leaderboard'>
                                    {userInfo ? <LeaderboardPage /> : ''}
                                    {isSavedUserInfoInvalid && isFacebookLoaded ? <Redirect to='/login' /> : ''}
                                </Route>
                                <Route path='/login'>
                                    <p>Tryk nedenfor for at logge ind med facebook</p>
                                    <Button
                                        label='Log ind med Facebook'
                                        iconPos='left'
                                        icon='fab fa-facebook'
                                        className='app__login__button p-button-info'
                                        onClick={() => fb.redirectToLogin()}
                                    />
                                </Route>
                                <Route path='/' exact={false}>
                                    {redirect ? redirect : ''}
                                </Route>
                            </Switch>
                        </div>
                    </Router>
                </ToastContext.Provider>
            </UserContext.Provider>
            <Toast ref={toastRef} />
        </div>
    );
}

export default App;
